.modalWindowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7);
  z-index: 1;
}

.modalWindow {
  width: 480px;
  max-height: calc(100% - 32px);
  overflow-y: scroll;
  padding: 44px 40px 40px 40px;
  border-radius: 5px;
  background: white;
}

.modalWindow h2 {
  margin-bottom: 12px;
}

.modalWindow h4 {
  margin-bottom: 4px;
}

.modalWindow .modalWindowSection {
  position: relative;
  width: 480px;
  left: -40px;
  padding: 32px 40px 0 40px;
  border-top: 1px solid #F0F0F0;
  margin-top: 32px
}

.modalWindow .buttonsWrapper {
  display: flex;
  margin-top: 40px;
}
