.pushLogsPage {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 14px;
  color: rgb(55,53,47);
  background: #F6F6F6;
  padding: 80px 11%;
}

.pushLogsPage h2 {
  color: white;
  margin-bottom: 32px;
}

.pushLogsPage div {
  margin-bottom: 18px;
}

.pushLogsPage span {
  display: inline-block;
  margin-right: 16px;
}

.pushLogsPage span.id {
  min-width: 24px;
  color: rgb(155,154,151);
}

.pushLogsPage span.time {
  color: #905;
}

.pushLogsPage span.data.success {
  color: green;
}
