.registrationPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 56px 0 24px 0;
  background-image: linear-gradient(180deg, #2A2A30 0%, #1F1F22 99%);
}

.registrationPage .logo {
  display: block;
  margin: 0 auto;
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: -0.32px;
  text-align: center;
  line-height: 40px;
}

.registrationPage .notification {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: white;
  background: #007AFF;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.12px;
  text-align: center;
  line-height: 16px;
  padding: 12px 48px;
}

.registrationPage .form {
  width: 384px;
  padding: 32px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0 4px 32px -4px rgba(0,0,0,0.80);
  border-radius: 4px;
}

.registrationPage .formLink a {
  display: block;
  width: 100%;
  font-size: 13px;
  color: #007AFF;
  letter-spacing: -0.12px;
  text-align: center;
  margin-top: 13px;
}

.registrationPage .formLink a:hover {
  text-decoration: underline;
}

.registrationPage .links {
  margin: 0 auto;
}

.registrationPage .links a {
  display: inline-block;
  font-size: 13px;
  color: #999999;
  letter-spacing: -0.12px;
  margin-right: 24px;
}

.registrationPage .links a:last-of-type {
  margin: 0;
}

.registrationPage .links a:hover {
  text-decoration: underline;
}
