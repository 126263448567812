.chanelCard {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #F4F4F4;
  box-shadow: 0 2px 5px -2px rgba(0,0,0,.05);
  border-radius: 5px;
  padding: 24px 20px 24px 24px;
}

.chanelCard.disable span,
.chanelCard.disable img {
  opacity: .3;
  filter: grayscale(100%);
}

.chanelCard img {
  border-radius: 48px;
  object-fit: cover;
}

.chanelCard .info {
  width: 100%;
  padding: 0 16px;
}

.chanelCard .info span {
  display: block;
  width: 100%;
}

.chanelCard .info span.name {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 22px;
  margin-bottom: 2px;
}

.chanelCard .info span.userName {
  font-size: 13px;
  color: #007AFF;
  letter-spacing: -0.2px;
  line-height: 18px;
}
