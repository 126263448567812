.menubarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 40px 0;
}

.menubarContainer .headingContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.menubarContainer h1 {
  font-weight: 500;
  font-size: 36px;
  color: #333333;
  letter-spacing: -0.24px;
  line-height: 40px;
  margin-right: 16px;
}

.menubarContainer p {
  font-weight: 500;
  font-size: 16px;
  color: #999999;
  letter-spacing: -0.11px;
  line-height: 20px;
}

.menubarContainer .questionButton {
  width: 40px;
  height: 40px;
  border: 1px solid #DEE1E5;
  border-radius: 14px;
  cursor: pointer;
}
