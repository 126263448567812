header.intent nav {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

header.intent .mainMenu,
header.intent .subMenu {
  display: flex;
}

header.intent .mainMenu {
  flex: 1;
}

header.intent nav .navItem {
  font-weight: 500;
  color: #8D8B94;
  line-height: 20px;

  transition: all .2s ease-in;
}

header.intent nav .navItem:hover {
  color: #333333;
}

header.intent nav .mainMenu .navItem {
  font-size: 13px;
  letter-spacing: -0.1px;
  margin-right: 32px;
}

header.intent nav .subMenu .navItem {
  font-size: 11px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-right: 24px;
}

header.intent nav .navItem.active {
  color: #333333;
}
