.shortProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.shortProfile img {
  border-radius: 28px;
  object-fit: cover;
}

.shortProfile span {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin: 0 8px 0 12px;
}
