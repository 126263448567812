
.formBlock {
    margin-bottom: 30px;
}

.formBlock .sourceTypeTab.active {
    color: #007AFF;
    border-color: #007AFF;
}

.formBlock .sourceTypeTab:hover {
    color: #007AFF;
}

.formBlock .sourceTypeTab:first-of-type {
    border-radius: 4px 0 0 4px;
}

.formBlock .sourceTypeTab:last-of-type {
    border-radius: 0 4px 4px 0;
}

.sourceTypeTab {
    font-weight: 500;
    font-size: 12px;
    color: #333333;
    line-height: 16px;
    padding: 8px 12px;
    border: 1px solid #F0F0F0;
    cursor: pointer;
    transition: all .2s ease-in;
}