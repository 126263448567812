.prizeCard {
  max-width: 168px;
  display: block;
  background-color: #ffffff;
  border-radius: 9px;
  padding: 24px 20px 20px;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  border: 2px solid white;
  box-shadow: 0 2px 5px -2px rgba(0,0,0,.05);
}

.prizeCard.active {
  border: 2px solid #007AFF;
  box-shadow: 0 2px 22px -2px rgba(0,0,0,.12);
}

.prizeCard > img {
  width: 128px;
  height: 128px;
  -o-object-fit: cover;
  object-fit: cover;
  max-height: 640px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.prizeCard h3 {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  letter-spacing: -0.2px;
  line-height: 16px;
  margin-bottom: 8px;
}

.prizeCard p.winnerName {
  font-size: 14px;
  color: #8D8B94;
  line-height: 16px;
}
