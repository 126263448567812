.productGroupWrapper {
  display: flex;
  padding-top: 64px;
  padding-bottom: 64px;

  background-image: linear-gradient(0deg, #FAFAFA 0%, #FFFFFF 100%);
}

.productCover img {
  width: 224px;
  height: 224px;
  box-shadow: 0 12px 32px -5px rgba(0,0,0,.1);
  border-radius: 24px;
}

.productGroupInfo {
  flex: 1;
  padding: 0 56px;
}

.productGroupInfo span.descriptor {
  font-weight: 500;
  font-size: 12px;
  color: #8D8B94;
  letter-spacing: 0.35px;
  line-height: 16px;
  margin-bottom: 6px;
}

.productGroupInfo h1 {
  margin-bottom: 8px;
}

.productGroupInfo p {
  font-size: 18px;
  color: #333333;
  letter-spacing: -0.21px;
  line-height: 24px;
}

.productGroupParametrs {
  display: flex;
  max-width: 630px;
  margin: 32px 0 48px 0;
}

.productGroupParametrs > div {
  margin-right: 48px;
}

.productGroupParametrs span {
  display: block;
  font-size: 14px;
  color: #6D6D72;
  line-height: 16px;
  margin-bottom: 4px;
}

.productGroupParametrs p {
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  letter-spacing: -0.32px;
}

.productGroupParametrs .productGroupColor {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.productTableHeading {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 4px;
  margin-top: 40px;
}

.productTableHeading div.addLink {
  font-weight: 500;
  font-size: 12px;
  color: #007AFF;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;
  cursor: pointer;
}
